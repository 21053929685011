<template>
  <div class="create-tax-page">
    <BackTitle confirm-click @click="goBack">New tax</BackTitle>
    <TaxForm
      :value="tax"
      submitButtonText="Add"
      :isSubmitting="isSubmitting"
      data-v-step="8"
      @submit="save"
    />
  </div>
</template>

<script>
import { NEW_TAX_MOCK } from "@/helpers/mocks";
import BackTitle from "@/components/common/BackTitle";
import TaxForm from "@/components/taxes/TaxForm";
import { mapActions } from "vuex";

export default {
  name: "CreateTaxPage",
  components: { TaxForm, BackTitle },
  data() {
    return {
      tax: { ...NEW_TAX_MOCK },
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      createTax: "taxes/createTax",
    }),
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.createTax(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Taxes",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-tax-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
