<template>
  <Form class="tax-form" v-slot="{ invalid, changed }">
    <div class="grid-2">
      <FormItem rules="required">
        <Input
          label="Tax type name"
          v-model="form.name"
          placeholder="Name"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem rules="required|decimal:2">
        <FormSuffix suffix="%">
          <Input
            label="Rate"
            v-model="form.rate"
            placeholder="Rate"
            :is-disabled="isDisabled"
          />
        </FormSuffix>
      </FormItem>
      <FormItem>
        <Input
          label="Description"
          v-model="form.description"
          placeholder="Description"
          isTextarea
          :is-disabled="isDisabled"
        />
      </FormItem>
    </div>
    <div class="tax-form__buttons mt-auto">
      <Button
        v-if="!isDisabled"
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="invalid || !changed || isDeleting"
        :isLoading="isSubmitting"
        @click="$emit('submit', form)"
      >
        {{ submitButtonText }}
      </Button>
      <Guard permission="taxes.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && hasDeleteButton"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          variant="danger"
          :isDisabled="isSubmitting"
          :isLoading="isDeleting"
          @click="remove"
        >
          Delete tax
        </Button>
      </Guard>
    </div>
  </Form>
</template>
<script>
import FormSuffix from "@/components/common/FormSuffix";
import { mapActions } from "vuex";
import dialog from "@/plugins/dialog";
import Guard from "@/components/common/Guard";

export default {
  name: "TaxForm",
  components: { Guard, FormSuffix },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
    };
  },
  methods: {
    ...mapActions({
      deleteTax: "taxes/deleteTax",
    }),
    async remove() {
      const confirmed = await dialog.confirm({
        title: "Delete Tax?",
        message: "All tax data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteTax(this.$route.params.id);
          await this.$router.push({
            name: "Taxes",
          });
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tax-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  height: 100%;

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      gap: 24px;
    }
  }
}
</style>
